@if (categories().length) {
  @for (category of categories(); track $index) {
    <mpcm-category-card
      [category]="category"
      [categoryType]="categoryTypeDescriptions[category.type]"
      [basePath]="basePath()"
      [expansionStateKeyPrefix]="CATEGORY_PROPERTIES_EXPANSION_STATE_KEY"
      [articleAttributes]="articleAttributes()"
    />
  }
} @else {
  <mat-card>
    <span class="no-result"> Keine Warengruppen vorhanden. </span>
  </mat-card>
}

@if (pharmaceuticalPropertiesViewInfos().length) {
  @for (propertyViewInfo of pharmaceuticalPropertiesViewInfos(); track $index) {
    <mpcm-key-value-panel
      headerName="Pharmaspezifische Eigenschaften"
      panelLabel="Alle pharmaspezifischen Eigenschaften"
      [keyValuesList]="propertyViewInfo.keyValues"
      [expansionStateKey]="propertyViewInfo.expansionKey"
      [isPanelHighlighted]="arePharmaceuticalPropertiesHighlithed()"
    />
  }
} @else {
  <mat-card>
    <span class="no-result"> Keine pharmaspezifischen Eigenschaften vorhanden. </span>
  </mat-card>
}
